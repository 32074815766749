import React, { useEffect } from 'react';

const DynamicStyles = ({ styles }) => {
  useEffect(() => {
    const links = styles.map((src) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = src;
      link.type = 'text/css';
      document.head.appendChild(link);

      // Cleanup to remove links when the component unmounts
      return () => {
        document.head.removeChild(link);
      };
    });

    // Cleanup effect for all added links
    return () => links.forEach((cleanup) => cleanup());
  }, [styles]);

  return null; // This component doesn't render anything
};

export default DynamicStyles;
