import React from "react";
import AdminDashboardHeaderNew from "./AdminDashboardHeaderNew";
import AdminDashboardSidebarMenuNew from "./AdminDashboardSidebarMenuNew";

const NewTheme = ({ children }) => {
  return (
    <div className="main-wrapper">
      <AdminDashboardHeaderNew />
      <AdminDashboardSidebarMenuNew />
      <div className="page-wrapper">
        <div className="content container-fluid">
            {children}
        </div>
      </div>
    </div>
  );
};

export default NewTheme;
