import React, { useState } from 'react'

/* old style css
import '../../css/header.css'
import '../../css/style.css'
import '../../css/dashboard.css'
import '../../css/plan.css'
 */

import DynamicStyles from './DynamicStyles'
import DynamicScripts from './DynamicScripts'

import { useNavigate } from 'react-router-dom'
import axioInstance from '../axiosInstance'
 

export default function AdminDashboardHeaderNew() {

    let navigate = useNavigate();

    const adminlogOut = () => {

        axioInstance.post(`login-logout-logs`, { user_type: 'adminclient', action: 'logout', admin_id: sessionStorage.getItem('admin_id') }).then(function (response, data) {

            sessionStorage.clear();

                // Clear "Remember Me" data
            localStorage.removeItem('remember_email');
            localStorage.removeItem('remember_password');
            localStorage.removeItem('remember_timestamp');

            navigate("/ulogin");

        });


    }
  
//const [adminRole, SetAdminRole] = useState( sessionStorage.getItem('admin_role') = 0 ? 'Admin' : 'Planner' );

const styles_arr = [
    '/assets/css/bootstrap.min.css',
    '/assets/plugins/fontawesome/css/fontawesome.min.css',
    '/assets/plugins/fontawesome/css/all.min.css',
    '/assets/plugins/feather/feather.css',
    '/assets/css/bootstrap-datetimepicker.min.css' ,
    '/assets/plugins/datatables/datatables.min.css',
    '/assets/css/style.css',
    '/assets/css/my-style.css',
];
 
const scripts = [
        '/assets/js/layout.js', 
        'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.7.0/jquery.min.js',
        "/assets/js/bootstrap.bundle.min.js",
        "/assets/plugins/datatables/datatables.min.js",
        "/assets/plugins/select2/js/select2.min.js",
        "/assets/plugins/slimscroll/jquery.slimscroll.min.js",
        "/assets/plugins/moment/moment.min.js",
        "/assets/js/bootstrap-datetimepicker.min.js",
        "/assets/js/jquery-ui.min.js", 
        '/assets/js/theme-settings.js',
        '/assets/js/greedynav.js', 
        
       /*  'https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.29.1/moment.min.js',
        'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datetimepicker/4.17.47/js/bootstrap-datetimepicker.min.js', */

        "/assets/js/script.js" ,
        "/assets/js/my-script.js" 
  ];

  const scriptsToRemove = [
    'https://code.jquery.com/jquery-3.2.1.slim.min.js'
  ];



    return (
        <>
         <DynamicStyles styles={styles_arr} />
         <DynamicScripts scripts={scripts} removeScriptSrcs={scriptsToRemove} />
           <div className="header header-one"> 

                <a href="/admin-dashboard"  className="d-inline-flex d-sm-inline-flex align-items-center d-md-inline-flex d-lg-none align-items-center device-logo">
                    <img src="images/logo-large.jpg" className="img-fluid logo2" alt="Logo" />
                </a>
                <div className="main-logo d-inline float-start d-lg-flex align-items-center d-none d-sm-none d-md-none">
                    <div className="logo-white">
                        <a href="/admin-dashboard">
                            <img src="images/logo-large.jpg" className="img-fluid logo-blue" alt="Logo" />
                        </a>
                        <a href="/admin-dashboard">
                            <img src="images/finnoNewLogo.jpg" className="img-fluid logo-small" alt="Logo" />
                        </a>
                    </div>
                    <div className="logo-color">
                        <a href="/admin-dashboard">
                            <img src="images/logo.png" className="img-fluid logo-blue" alt="Logo" />
                        </a>
                        <a href="/admin-dashboard">
                            <img src="images/finnoNewLogo.jpg" className="img-fluid logo-small" alt="Logo" />
                        </a>
                    </div>
                </div>


                <a href="/#" id="toggle_btn">
                    <span className="toggle-bars">
                    <span className="bar-icons"></span>
                    <span className="bar-icons"></span>
                    <span className="bar-icons"></span>
                    <span className="bar-icons"></span>
                    </span>
                </a>
                <div className="top-nav-search">
                     <form>
                        <input type="text" className="form-control" placeholder="Search here" />
                        <button className="btn" type="submit"><img src="assets/img/icons/search.svg" alt="img" /></button>
                    </form>
                </div>

                <a href="/#" className="mobile_btn" id="mobile_btn">
                    <i className="fas fa-bars"></i>
                </a>

                <ul className="nav nav-tabs user-menu">
                    <li className="nav-item  has-arrow dropdown-heads ">
                    <a  href="/#" className="win-maximize">
                        <i className="fe fe-maximize"></i>
                    </a>
                    </li>

                    <li className="nav-item dropdown">
                    <a
                        href="/#"
                        className="user-link  nav-link"
                        data-bs-toggle="dropdown"
                    >
                        <span className="user-img">
                         <img src="assets/img/profiles/avatar-07.jpg" alt="img" className="profilesidebar" />
                        <span className="animate-circle"></span>
                        </span>
                        <span className="user-content">
                        <span className="user-details">Admin</span>
                        <span className="user-name">John Smith</span>
                        </span>
                    </a>
                    <div className="dropdown-menu menu-drop-user">
                        <div className="profilemenu">
                        <div className="subscription-menu">
                            <ul>
                            <li>
                                <a className="dropdown-item" href="/#">
                                Profile
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="/#">
                                Settings
                                </a>
                            </li>
                            </ul>
                        </div>
                        <div className="subscription-logout">
                            <ul>
                            <li>
                                <a onClick={adminlogOut} className="dropdown-item" href="#">
                                    Logout
                                </a>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                    </li>
                </ul>
                </div>
        </>
    )
}