import React, { useState } from 'react'
/* 
import '../../css/header.css'
import '../../css/style.css'
import '../../css/dashboard.css'
import '../../css/plan.css'
 */
// new style import
/* import '../../assets/css/bootstrap.min.css'
import '../../assets/plugins/fontawesome/css/fontawesome.min.css'
import '../../assets/plugins/fontawesome/css/all.min.css'
import '../../assets/plugins/feather/feather.css'
import '../../assets/css/bootstrap-datetimepicker.min.css' 
import '../../assets/plugins/datatables/datatables.min.css'
import '../../assets/css/style.css'    */

import { Link, useNavigate } from 'react-router-dom'
import AddTestProfileSidebar from '../AddTestProfileSidebar';
import AddDirectProfileSidebar from '../AddDirectProfileSidebar';
//import ProfileInterestStatus from '../ProfileInterestStatus';
//import axioInstance from '../axiosInstance'

export default function AdminDashboardSidebarMenuNew() {

    let navigate = useNavigate();
  const [isActive, setActive] = useState(false);
  const [action, setAction] = useState();


    const addProfile = () => {

      console.log('hostname', window.location);
  
      const url = window.location.origin + "/sign-in?q=2&ref_by=" + sessionStorage.getItem('shortName') + "&s=" + sessionStorage.getItem('admin_id');
  
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
  
    }
    

     
    const planEventsRedirect = () => {
       navigate('/profile-event-logs-dashboard');
    }
     
    return (
        <>
    <div className="sidebar" id="sidebar">
      
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title"><span>Main</span></li>
            <li className="submenu">
              <Link href="#">
                <i className="fe fe-home"></i> <span>Admin</span>{" "}
                <span className="menu-arrow"></span>
              </Link>
              <ul>
                <li><Link to='/admin-dashboard-new' >Admin Dashboard</Link></li> 
                <li><Link onClick={addProfile}>Generate Sign In Link</Link></li>
                <li><Link onClick={() => { setActive(true); setAction('addTestProfile') }}>Add Testing profile</Link></li>
                {
              (sessionStorage.getItem('admin_id') === '1' || sessionStorage.getItem('admin_id') === '27' || sessionStorage.getItem('admin_id') === '7' || sessionStorage.getItem('admin_id') === '61') && (
              <>
                <li><Link to='/guest-users-dashboard'> Guest Users</Link></li>  
                <li><Link to='/plan-login-logs-dashboard'>Plan Login Logs</Link></li> 
              </>)
              }
                <li><Link onClick={() => { setActive(true); setAction('addDirectProfile') }} >Add Guest User</Link></li>  
                <li><Link to='/profile-event-logs-dashboard' >Plan Events Logs</Link></li>
              </ul>
            </li>

            {
              sessionStorage.getItem('admin_id') === '1' && (
            <li className="submenu">
              <Link href="#">
                <i className="fe fe-file"></i> 
                <span>Plan Reports</span>
                <span className="menu-arrow"></span>
              </Link>
              <ul>
                <li><Link to='/plan-profiles-timeline'>Profile Timeline</Link></li>
                <li><Link to='/plan-profiles-status'>Profile Plan Status</Link></li> 
                <li><Link to='/plan-summary-report-drilldown-month'>Summary Month Wise</Link></li> 
              </ul>
            </li>)
          }


          
          </ul>

         {/*  <ul>
            <li className="menu-title"><span>Plan Reports</span></li>
            <li>
              <a href="customers.html">
                <i className="fe fe-users"></i> <span>Customers</span>
              </a>
            </li>
            <li>
              <a href="customer-details.html">
                <i className="fe fe-file"></i> <span>Customer Details</span>
              </a>
            </li>
            <li>
              <a href="vendors.html">
                <i className="fe fe-user"></i> <span>Vendors</span>
              </a>
            </li>
          </ul>

          <ul>
            <li className="menu-title"><span>Settings</span></li>
            <li>
              <a href="settings.html">
                <i className="fe fe-settings"></i> <span>Settings</span>
              </a>
            </li>
            <li>
              <a href="login.html">
                <i className="fe fe-power"></i> <span>Logout</span>
              </a>
            </li>
          </ul> */}

        </div>
      </div>
    </div>

      {
        (isActive === true && action === 'addTestProfile') && (<AddTestProfileSidebar setActive={setActive} setAction={setAction} />)
      }

      {
        (isActive === true && action === 'addDirectProfile') && (<AddDirectProfileSidebar setActive={setActive} setAction={setAction} />)
      } 
  </>
  )
}