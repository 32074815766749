import React, { useEffect } from "react";

export default function AdminDashboardFilterForm(props) {
  return (
    <>
      <div className="toggle-sidebar" id="filter_sidebar_0">
        <div className="sidebar-layout-filter">
          <div className="sidebar-header">
            <h5>Filter Table Data</h5>
            <a href="#" className="sidebar-closes">
              <i className="fa-regular fa-circle-xmark"></i>
            </a>
          </div>
          <div className="sidebar-body">

              <div className="form-group">
                <label>Name</label>
                <input type="text" className="filter_name form-control"  id="admin_filter_name" placeholder="Search Name" />
              </div>

              <div className='form-group'>
              <label>Rel Manager Filter</label>
                <select className="custom-select form-control" name='relManager' onChange={(e) => props.relmanagerFilter(e)}>
                  <option value="">Select Rel Manager</option>
                  {props.relManager.map((data, i) => {
                    return (
                      <option value={data.admin_id} key={i}>{data.admin_name}</option>
                    )
                  })}
                </select> 
              </div>
              

              <div className="form-group">
                <label>Profile Filter</label>
                  <select className="form-control custom-select" name='profileFilter' onChange={(e) => props.profileFilter(e)}>
                      <option value="">Select</option>
                      <option value={""} selected={props.ptype === "" ? "selected" : ""}>{"ALL"}</option>
                      <option value={"3"} selected={props.ptype === "3" ? "selected" : ""}>{"Active"}</option>
                      <option value={"2"} selected={props.ptype === "2" ? "selected" : ""}>{"Waitlisted"}</option>
                      <option value={"1"} selected={props.ptype === "1" ? "selected" : ""}>{"Testing"}</option>
                    </select>
              </div>

              <div className="form-group">
                <label>Risk Profile Filter</label>
                  <select className="form-control custom-select" name='riskprofileFilter' onChange={(e) => props.riskprofileFilter(e)}>
                      <option value="">Select</option>
                      <option value={"0"} selected={props.riskptype === "0" ? "selected" : ""}>{"ALL"}</option>
                      <option value={"1"} selected={props.riskptype === "2" ? "selected" : ""}>{"Done"}</option>
                      <option value={"2"} selected={props.riskptype === "1" ? "selected" : ""}>{"Not Done"}</option>
                    </select>
              </div>

              <div className="form-group">
                <label>Plan Stage Filter</label>
                <select className="form-control custom-select" name='planStageFilter' onChange={(e) => props.planStageFilter(e)}>
                  <option value="">Select</option>
                  <option value={"1"} selected={props.planStage === "1" ? "selected" : ""}>{"Goals Created Till Retirement"}</option>
                  <option value={"2"} selected={props.planStage === "2" ? "selected" : ""}>{"Steps Completed"}</option>
                  <option value={"3"} selected={props.planStage === "3" ? "selected" : ""}>{"Plan Completed"}</option>
                  <option value={"4"} selected={props.planStage === "4" ? "selected" : ""}>{"Ready to Share"}</option>
                  <option value={"5"} selected={props.planStage === "5" ? "selected" : ""}>{"Plan Shared"}</option>
                  <option value={"6"} selected={props.planStage === "6" ? "selected" : ""}>{"Plan Re Shared"}</option>
                  <option value={"7"} selected={props.planStage === "7" ? "selected" : ""}>{"Plan Freezed"}</option>
                  <option value={"9"} selected={props.planStage === "9" ? "selected" : ""}>{"Execution Done"}</option>
                </select>
              </div>


              
              
            <br />
            <br />
        {/* 
            <div className="filter-buttons">
              <button
                type="submit"
                className="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary"
              >
                Apply
              </button>
              <button
                type="submit"
                className="d-inline-flex align-items-center justify-content-center btn w-100 btn-secondary"
              >
                Reset
              </button>
            </div>
             */}
          </div>
        </div>
      </div>
    </>
  );
}
