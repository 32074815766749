import React, { Fragment, useState, useEffect } from 'react';
import axioInstance from '../components/axiosInstance';
import { stepMaster, registerVia } from '../components/global'
import { Link, useNavigate } from 'react-router-dom' 
import Register from './Register';
import * as copy from 'copy-to-clipboard';
import { Dropdown } from 'react-bootstrap'
import SweetAlert from 'sweetalert2'
import PlanStatus from './PlanStatus';
import { Modal, ModalBody } from 'react-bootstrap';
import AddTestProfileSidebar from '../components/AddTestProfileSidebar';
import ProfileInterestStatus from '../components/ProfileInterestStatus';
import AddDirectProfileSidebar from '../components/AddDirectProfileSidebar';
import { Encryption } from '../components/global';

import AdminDashboardHeaderNew from '../components/admin-dashboard/AdminDashboardHeaderNew';
import AdminDashboardSidebarMenuNew from '../components/admin-dashboard/AdminDashboardSidebarMenuNew';
 
import CardBox from '../components/admin-dashboard/CardBox';
import AdminDashboardFilterForm from '../components/admin-dashboard/AdminDashboardFilterForm'; 

export default function AdminDashboardNew() {
  let navigate = useNavigate();
  /*sessionStorage.setItem('admin_id', "1");
  sessionStorage.setItem('admin_role', "0");*/
  // eslint-disable-next-line
  const [data, setData] = useState([])
  const [tableRowsData, setTableRowsData] = useState([]);

  const [ptype, setPtype] = useState("");
  const [riskptype, setRisktype] = useState("0");

  const [planStage, setPlanStage] = useState("0");

  // Read ptype from URL and set it as initial state
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ptypeFromUrl = params.get('ptype');

    const risktypeFromUrl = params.get('risktype');

    const planStageUrl = params.get('planstage');
    console.log('ptypeFromUrl', ptypeFromUrl);
    console.log('risktypeFromUrl', risktypeFromUrl);

    if (ptypeFromUrl !== null) {
      setPtype(ptypeFromUrl);
    }

    if (risktypeFromUrl !== null) {
      setRisktype(risktypeFromUrl);
    }

    if (planStageUrl !== null) {
      setPlanStage(planStageUrl);
    }

  }, []);



  const loginUser = (id, emailAddress) => {
    axioInstance.get(`admin/user-login/` + id).then(
      (response, data) => {
        sessionStorage.setItem('token', response.data.token);
        const pdata = { "email": Encryption(emailAddress) };
        axioInstance.post(`profile/view`, pdata).then(function (response) {
          

          sessionStorage.setItem('profile_id', response.data.enc_profile);
          sessionStorage.setItem('full_name', response.data.first_name + '\'s');
          sessionStorage.setItem('profile_details_id', response.data.enc_profile_details_id);

          axioInstance.post(`login-logout-logs`, { user_type: 'adminclient', action: 'login', profile_id: response.data.enc_profile, admin_id: sessionStorage.getItem("admin_id") });

          if (response.data.step > 3) {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/basic-profile";
          }
        });
      }
    );
  }

  useEffect(() => {
    const loggedInUser = localStorage.getItem("admin_id");
    console.log(loggedInUser);
    if (loggedInUser === null) {
      navigate("/ulogin");
    } else {

      const params = new URLSearchParams(window.location.search);
      const ptype = params.get('ptype') ? params.get('ptype') : '';
      const risktype = params.get('risktype') ? params.get('risktype') : '';
      const planstage = params.get('planstage') ? params.get('planstage') : '';
      const PostData = {
        rel_manager: localStorage.getItem('admin_id'),
        admin_role: sessionStorage.getItem('admin_role'),
        testProfile: ptype,
        risktype: risktype,
        planstage:planstage,
        encrypted_key:Encryption("anojtambe")
      }
      /*const filteredPostData = Object.fromEntries(
        Object.entries(PostData).filter(([_, value]) => value !== '')
      );*/

      axioInstance.post(`admin/user-profiles`, PostData).then(
        (response, data) => {
          //console.log("line 108:",response.data);
          setData(response.data);
          setTableRowsData(response.data);
        }
      );

      axioInstance.get(`admin/get-admins`).then(
        (response, data) => {
          //console.log(response.data);
          setRelManager(response.data);
        }
      );

      if (sessionStorage.getItem('shortName') && sessionStorage.getItem('shortName') !== null) {
        // do nothing
      } else {

        axioInstance.post(`admin/get-admin-shortname`, { email: sessionStorage.getItem('admin_email') }).then(
          (response, data) => {

            if (response.data.status === 100) {
              sessionStorage.setItem('shortName', response.data.relmanager.short_name);
            } else {
              sessionStorage.setItem('shortName', 'FINNOVATE');
            }

          }
        );

      }

    }
  }, [navigate]);

  const customStyles = {
    rows: {
      style: {
        minHeight: '65px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for the head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  const [profileId, setProfileId] = useState();

  const columns = [
    {
      name: 'Action',
      selector: row => <></>,
      cell: (d) => <>         <Dropdown>
        <Dropdown.Toggle id="dropdown-basic" variant="success" className='planstatus_dots'>
          <i className="fas fa-ellipsis-v" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {/*<Dropdown.Item onClick={() => EditRelManager(d.profile_id)}><i className="fa fa-user text-primary" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Edit Rel Manager</Dropdown.Item>*/}

          <Dropdown.Item onClick={() => deleteUser(d.id, d.profile_id)}><i className="fas fa-trash-alt text-danger" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Delete User</Dropdown.Item>

          <Dropdown.Item onClick={() => RiskLink(d.id, d.enc_profile)}> <i className="fas fa-paste text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Risk Profile Link</Dropdown.Item>
          {
            d.waitlist === 1 && <Dropdown.Item onClick={() => waitlistPopup(d.profile_id)}> <i className="fa fa-user text-primary" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Mark Active </Dropdown.Item>
          }
          <Dropdown.Item onClick={() => EditPlanStatus(d.profile_id)}> <i className="fas fa-stream text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Update Plan Status</Dropdown.Item>

          <Dropdown.Item onClick={() => markTestProfile(d.profile_id)}> <i className="fas fa-user-alt-slash text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Mark as Test Profile</Dropdown.Item>
          <Dropdown.Item onClick={() => setDefaultPassword(d.email)}> <i className="fas fa-unlock text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Set Password</Dropdown.Item>
          <Dropdown.Item onClick={() => get_profile_pan(d.profile_id)}> <i className="fas fa-id-card text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Get PAN</Dropdown.Item>
          <Dropdown.Item onClick={() => { setActive(true); setAction('profileInterest'); setProfileId(d.profile_id) }}> <i className="fas fa-tasks text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> Profile Interest </Dropdown.Item>
          <Dropdown.Item onClick={() => getViewLink(d.ref_link)}> <i className="fas fa-id-card text-dark" style={{ width: 35, fontSize: 16, padding: 11 }}></i> View URL</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> <span className='cursor_pointer' onClick={() => loginUser(d.id, d.email)}><i className="fas fa-sync-alt" style={{ width: 35, fontSize: 16, padding: 11, color: '#06B153' }}></i></span></>,
      width: '90px'
    },
    {
      name: 'ID',
      selector: row => row.profile_id,
      sortable: true,
      width: '90px'
    },
    {
      name: 'Name | Status',
      selector: row => row.name,
      sortable: true,
      cell: row => {

        let color;
        let status;

        if (row.testProfile === 1) {
          color = 'red';
          status = 'Testing';
        } else if (row.waitlist === 1) {
          color = 'orange';
          status = 'Waitlisted';
        } else {
          color = 'green';
          status = 'Active';
        }
        return <><span>{row.name} <br /> <span style={{ color }}>{status}</span></span></>;
      },
      //width: '190px'
    },
    {
      name: 'Email | Phone',
      selector: row => row.email,
      sortable: true,
      width: '350px', // Set the width of the column
      cell: row => {
        let color;

        if (row.email_verified === "verified") {
          color = 'green';
        } else {
          color = 'red';
        }

        return <span style={{ color }}>{row.email} <br /> {row.phone}</span>;
      }
    },
    {
      name: 'UTM Source | Reg. Via',
      selector: row => row.utm_source,
      sortable: true,
      //width: '190px',
      cell: row => {
        return <span>{row.utm_source ? row.utm_source : 'NA'} <br /> {registerVia(row.registerVia)}</span>;
      }
    },
    {
      name: 'Rel Manager',
      selector: row => row.primary_rel_manager,
      sortable: true,
      //width: '120px'
    },
    {
      name: 'Planner',
      selector: row => row.plan_ser_manager,
      sortable: true,
      //width: '120px'
    },
    {
      name: 'Created By | Created On',
      selector: row => row.admin_name,
      sortable: true,
      //width: '120px',
      cell: row => {
        return <span>{row.admin_name} <br /> {row.create_on}</span>;
      }
    },
    {
      name: 'Stage On | Plan Status',
      selector: row => row.planStatusCurrent,
      sortable: true,
      cell: row => {
        return <span>{stepMaster(row.step)} <br /> {row.planStatusCurrent}</span>;
      }
    }
  ];

  const RiskLink = (id, enc_profile) => {
    const link = 'https://plan.finnovate.in/risk-profile-set?profile_id=' + enc_profile;

    if (copy(link)) {

      const config = {
        headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` }
      };

      const postData = { link: link, module_name: 'risk_profile', profile_id: Encryption(id), created_by: sessionStorage.getItem('admin_id') };
      axioInstance.post(`url-shared`, postData, config).then(
        (response, data) => {

        }
      );

      SweetAlert.fire({
        toast: true,
        icon: 'success',
        title: 'Link Copied Successfully',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', SweetAlert.stopTimer)
          toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
        }
      })
    } else {
      SweetAlert.fire(
        'Copy Link',
        'Something went wrong',
        'error'
      );
    }


  }

  const UpdateRelManager = () => {
    var postData = {
      'profile_id': profile_id,
      'rel_manager': admin_id
    }
    axioInstance.post(`profile/update-relmanager`, postData)
      .then(function (response) {

        SweetAlert.fire(
          'Profile',
          'Successfully Updated Rel Manager.',
          'success'
        ).then(function () {
          window.location.href = "/admin-dashboard"
        })

      })
      .catch(function (error) {
        SweetAlert.fire(
          'Profile',
          'Failed.',
          'error'
        )
      });
  }


  const getViewLink = (ref_url) => {



    if (ref_url) {

      SweetAlert.fire({
        title: "REF URL",
        text: ref_url ? ref_url : 'NA',
        icon: "success"
      });

    } else {
      SweetAlert.fire({
        title: "REF URL",
        text: "NA",
        icon: "error"
      });
    }




  }

  const get_profile_pan = (profile_id) => {

    axioInstance.post(`profile/pan`, { 'profile_id': Encryption(profile_id) })
      .then(function (response) {

        if (response.data.pan) {

          SweetAlert.fire({
            title: response.data.pan,
            text: "PAN",
            icon: "success"
          });

        } else {
          SweetAlert.fire({
            title: "Error",
            text: "Something went wrong or No PAN Found",
            icon: "error"
          });
        }

      })
      .catch(function (error) {
        SweetAlert.fire(
          'Error',
          'Something went wrong or No PAN Found.',
          'error'
        )
      });


  }

  const setDefaultPassword = (email) => {

    SweetAlert.fire({
      title: "Set Default Password",
      text: "This will change the current password. Continue ?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {


          axioInstance.post(`profile/update-password`, { 'email': email, 'confirm_password': 'Finno@1234' }).then(
            function (response) {

              SweetAlert.fire({
                toast: true,
                icon: 'success',
                title: 'Successfully Set Default Password - Finno@1234',
                animation: false,
                position: 'top-right',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', SweetAlert
                    .stopTimer)
                  toast.addEventListener('mouseleave', SweetAlert
                    .resumeTimer)
                }
              }).then(function () {

                window.location.reload();

              });
            }
          )

        }
      });

  }

  const markTestProfile = (profile_id) => {

    SweetAlert.fire({
      title: "Mark as Test Profile",
      text: "Are you sure want to mark as Test profile?",
      type: "question",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      closeOnCancel: false
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        axioInstance.post(`profile/mark-as-testprofile`, { 'profile_id': profile_id }).then(
          function (response) {

            SweetAlert.fire({
              toast: true,
              icon: 'success',
              title: 'Successfully Marked Profile as Testing',
              animation: false,
              position: 'top-right',
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener('mouseenter', SweetAlert
                  .stopTimer)
                toast.addEventListener('mouseleave', SweetAlert
                  .resumeTimer)
              }
            }).then(function () {

              window.location.reload();

            });
          }
        )
      }
    });

  }

  const deleteUser = (id, profile_id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "you want to delete this user?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: false
    })
      .then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axioInstance.post(`admin/user-delete`, { 'id': id, 'profile_id': Encryption(profile_id) }).then(
            function (response) {
              SweetAlert.fire(
                'User',
                'Successfully Deleted.',
                'success'
              ).then(function () {
                window.location.href = "/admin-dashboard";
              });
            }
          )
        }
      });
  }

  /*const EditRelManager = (id) => {
    setProfile_id(id);
    toggleShow();
  }*/



  const [title, setTitle] = useState("");
 
  const onChange = async (e) => {
    setTitle(e.target.value);
    
    const searchValue = e.target.value.toLowerCase();
  
    // Filter the data safely
    const searchData = data.filter((item) => {
      return (
        (item.name && item.name.toString().toLowerCase().includes(searchValue)) ||
        (item.email && item.email.toString().toLowerCase().includes(searchValue)) ||
        (item.profile_id && item.profile_id.toString().toLowerCase().includes(searchValue)) ||
        (item.phone && item.phone.toString().toLowerCase().includes(searchValue)) ||
        (item.primary_rel_manager && item.primary_rel_manager.toString().toLowerCase().includes(searchValue)) ||
        (item.admin_name && item.admin_name.toString().toLowerCase().includes(searchValue)) ||
        (item.plan_ser_manager && item.plan_ser_manager.toString().toLowerCase().includes(searchValue))
      );
    });
  
    setTableRowsData(searchData);
  };

  const relmanagerFilter = async (e) => {
    // eslint-disable-next-line
    var searchData = data.filter((item) => {

      if (
        parseInt(item.admin_id) === parseInt(e.target.value)
      ) {
        return item;
      }


    });


    setTableRowsData(e.target.value > 0 ? searchData : data);
  }

  /*const waitlistFilter = async (e) => {
    // eslint-disable-next-line
    var searchData = data.filter((item) => {

      if (
        parseInt(item.waitlist) === parseInt(e.target.value)
      ) {
        return item;
      }


    });


    setTableRowsData(e.target.value < 0 ? data : searchData);
  }*/


  const profileFilter = async (e) => {

    const selectedValue = e.target.value;
    const url = new URL(window.location.href);
    url.searchParams.set('ptype', selectedValue);
    window.location.href = url.toString();

  }

  const riskprofileFilter = async (e) => {

    const selectedValue = e.target.value;
    const url = new URL(window.location.href);
    url.searchParams.set('risktype', selectedValue);
    window.location.href = url.toString();

  }

  const planStageFilter = async (e) => {

    const selectedValue = e.target.value;
    const url = new URL(window.location.href);
    url.searchParams.set('planstage', selectedValue);
    window.location.href = url.toString();

  }



  const [relManager, setRelManager] = useState([])
  const [profile_id, setProfile_id] = useState();
  const [modal, setModal] = useState(false);
  //const toggleShow = () => setModal(true);
  const toggleClose = () => setModal(false);

  const [admin_id, setadmin_id] = useState();


  const handleChange = (e) => {
    const { value } = e.target;
    setadmin_id(value);
  }


  const addProfile = () => {

    console.log('hostname', window.location);

    const url = window.location.origin + "/sign-in?q=2&ref_by=" + sessionStorage.getItem('shortName') + "&s=" + sessionStorage.getItem('admin_id');

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

  }

  const [show, setShow] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);


  const [planStatus, setPlanStatusShow] = useState(false);
  const planStatusClose = () => setPlanStatusShow(false);
  const planStatusShow = () => setPlanStatusShow(true);

  const EditPlanStatus = (id) => {
    setProfile_id(id);
    planStatusShow();
  }

  const [waitlisProfile, setWaitlistProfile] = useState();
  const waitlistPopup = (id) => {
    setShowWaitlist(true);
    setWaitlistProfile(id);
  }

  const updateWaitlist = (id) => {

    axioInstance.post(`profile/update-profile-waitlist`, { 'profile_id': Encryption(waitlisProfile) }).then(
      function (response) {

        SweetAlert.fire({
          toast: true,
          icon: 'success',
          title: 'Successfully Updated Profile',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
          }
        }).then(function () {

          window.location.href = "/admin-dashboard";

        });

      }
    )

  }

  const [isActive, setActive] = useState(false);

  const [action, setAction] = useState();

  const geuestUsersRedirect = () => {
    window.location.href = 'guest-users-dashboard';
    return;
  }

  const planLogsRedirect = () => {
    window.location.href = 'plan-login-logs-dashboard';
    return;
  }

  const planEventsRedirect = () => {
    window.location.href = 'profile-event-logs-dashboard';
    return;
  }

  
/*  
useEffect(()=>{ 

    if (typeof window.applyDataTableAdvance === "function") {
          window.applyDataTableAdvance('datatable_2', {  }) 
    } 
}, [tableRowsData]) */



useEffect(() => {
  // Ensure the DOM is ready and `applyDataTable` is available globally
  if (typeof window.applyDataTableAdvance === "function") {

      window.applyDataTableAdvance(
        '#datatable_2', 
        {
          "bFilter": true,
        }, 
        function(datatable) {
          
          // Add custom filtering logic
            const adminFilterInput = document.getElementById('admin_filter_name');
            if (adminFilterInput) {
                adminFilterInput.addEventListener('keyup', function() {
                  datatable.column(2).search(this.value).draw();
                });
            }

        }

      );
  }
}, [tableRowsData]); // Empty dependency array ensures this runs only once on mount



 

  return (
    <Fragment>
    <div className="main-wrapper">
      <AdminDashboardHeaderNew />
      <AdminDashboardSidebarMenuNew />

      <AdminDashboardFilterForm 
        relmanagerFilter={relmanagerFilter}
        relManager={relManager}
        profileFilter={profileFilter} 
        ptype={ptype} 
        riskprofileFilter={riskprofileFilter} 
        riskptype={riskptype} 
        planStageFilter={planStageFilter} 
        planStage={planStage} 
        />

      <div className="page-wrapper">
        <div className="content container-fluid">


            <div className="page-header"> 
                <div className="content-page-header">
                    <h5>Admin Dashboard</h5>
                    <div className="list-btn">
                        <ul className="filter-list">
                            <li>
                                <Link className="btn btn-filters w-auto popup-toggle" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-original-title="Filter">
                                    <span className="me-2"><img src="assets/img/icons/filter-icon.svg"   alt="filter" /></span>Filter 
                                </Link>
                            </li>  
                          {/*   <li>
                                <a className="btn btn-primary" href="add-invoice.html"><i className="fa fa-plus-circle me-2" aria-hidden="true"></i>New Invoice</a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </div>

            

            <Modal show={showWaitlist} onHide={handleClose} className='modal model-sm' dialogClassName='modal-container' keyboard centered>
              <ModalBody>
                <div className="p20">
                  <div className="text-center">
                    <span className={"addIcon"} />
                  </div>
                  <div className="text-center mt10 color1e3 fw600 font18">
                    {"Waitlisted"}
                  </div>
                  <div className="text-center mt10 color485 fw500 font14">
                    {"Are you sure want to remove from waitlist and active profile"}
                  </div>
                  <div className="smallbtn mt20">
                    <button type="button" className="btn btn-outline-secondary" onClick={handleClose}>Cancel</button>
                    <button type="button" className={"btn btn-danger"} onClick={updateWaitlist}>Yes</button>
                  </div>
                </div>
              </ModalBody>
            </Modal>


            <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                <Register ulogin={1} relManager={sessionStorage.getItem('admin_id')} />
              </Modal.Body>
            </Modal>

            <Modal show={planStatus} onHide={planStatusClose}>
              <Modal.Body>
                <PlanStatus planStatusClose={planStatusClose} profile_id={profile_id} />
              </Modal.Body>
            </Modal>

            <Modal show={modal} onHide={toggleClose}>
              <Modal.Header>{"Assign/Update Rel Manager"}</Modal.Header>
              <Modal.Body>
                <div className='col-md-12'>
                  <br />
                  <select className="form-control digits" name={"admin_id"} value={admin_id} onChange={handleChange} autoComplete="off">
                    <option value="">select</option>
                    {relManager.map((data, i) => {
                      return (
                        <option value={data.admin_id} key={i}>{data.admin_name}</option>
                      )
                    })}
                  </select>
                  <br />
                  <button className="btn btn-primary" type='button' onClick={() => UpdateRelManager()}> Update Rel Manager </button>
                </div>
              </Modal.Body>
            </Modal>

            <div className="innercontainer_ _isPlanProfilesTimelinePage mb40_">
              <div className="mainwrapper_">  
                <div className="justify-content-left row mt35" style={{display:'none'}}>
                  <div className="col-lg-2 col-md-2">
                    <button type="button" className="btn btn-default btn-block btnFinno" onClick={addProfile}>Generate Sign In Link</button>
                  </div>
                  <div className="col-lg-2 col-md-2">
                    <button type="button" className="btn btn-default btn-block btnFinno" onClick={() => { setActive(true); setAction('addTestProfile') }}> Add Testing Profile </button>
                  </div>

                  {
                    (sessionStorage.getItem('admin_id') === '1' || sessionStorage.getItem('admin_id') === '27' || sessionStorage.getItem('admin_id') === '7' || sessionStorage.getItem('admin_id') === '61') && (<>
                      <div className="col-lg-2 col-md-2">
                        <button type="button" className="btn btn-default btn-block btnFinno" onClick={() => geuestUsersRedirect()}>Guest Users</button>
                      </div>
                      <div className="col-lg-2 col-md-2">
                        <button type="button" className="btn btn-default btn-block btnFinno" onClick={() => planLogsRedirect()}>Plan Login Logs</button>
                      </div>
                    </>

                    )
                  }
 
                  <div className="col-lg-2 col-md-2">
                    <button type="button" className="btn btn-default btn-block btnFinno" onClick={() => { setActive(true); setAction('addDirectProfile') }}>Add Guest User </button>
                  </div> 
                  {
                    sessionStorage.getItem('admin_id') === '1' && (
                      <>
                        <div className="col-lg-2 col-md-2">
                          <Dropdown className="">
                            <Dropdown.Toggle id="dropdown-basic" className="planstatus_dots dropdown-toggle btn btn-success">
                              Plan Reports
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href='plan-profiles-timeline'><i className="fas fa-calendar text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i> Profile Timeline</Dropdown.Item>
                              <Dropdown.Item href='plan-profiles-status'><i className="fas fa-users text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Profile Plan Status</Dropdown.Item>
                              <Dropdown.Item href='plan-summary-report-drilldown-month'><i className="fas fa-bar-chart text-dark" style={{ width: 35, fontSize: 14, padding: 11 }}></i>Summary Month Wise</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>

                        <div className="col-lg-2 col-md-2 mt25">
                          <button type="button" className="btn btn-default btn-block btnFinno" onClick={() => planEventsRedirect()}>Plan Events Logs</button>
                        </div>
                      </>
                    )
                  }

                </div> 
                  <div className="row">
                    <CardBox icon="assets/img/icons/receipt-item.svg" title="Total Invoice" value={345} counter_value="3" counter_title="No of Invoice"/>
                    <CardBox icon="assets/img/icons/transaction-minus.svg" title="Outstanding" value={345} counter_value="3" counter_title="No of Invoice"/>
                    <CardBox icon="assets/img/icons/archive-book.svg" title="Total Overdue" value={345} counter_value="3" counter_title="No of Invoice"/>
                    <CardBox icon="assets/img/icons/clipboard-close.svg" title="Cancelled" value={345} counter_value="3" counter_title="No of Invoice"/>
                    <CardBox icon="assets/img/icons/message-edit.svg" title="Draft" value={345} counter_value="3" counter_title="No of Invoice"/>
                    <CardBox icon="assets/img/icons/3d-rotate.svg" title="Recurring" value={345} counter_value="3" counter_title="No of Invoice"/>  
                  </div>
 
{/* 
sample table layout
<div className="row">
    <div className="col-sm-12">
        <div className="card-table">
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-stripped table-hover datatable_1_" id="datatable_1">
                        <thead className="thead-light">
                            <tr>
                                <th>
                                    <label className="custom_check">
                                        <input type="checkbox" name="invoice" /><span className="checkmark"></span>
                                    </label>Invoice ID
                                </th>
                                <th>Category</th>
                                <th>Issued On</th>
                                <th>Invoice To</th>
                                <th>Total Amount</th>
                                <th>Paid Amount</th>
                                <th>Payment Mode</th>
                                <th>Balance</th>
                                <th>Due Date</th>
                                <th>Status</th>
                                <th className="text-end">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <label className="custom_check">
                                        <input type="checkbox" name="invoice" />
                                        <span className="checkmark"></span>
                                    </label>
                                    <a href="invoice-details.html" className="invoice-link">#4987</a>
                                </td>
                                <td>Food</td>
                                <td>23 Mar 2023</td>
                                <td>
                                    <h2 className="table-avatar">
                                        <a href="profile.html" className="avatar avatar-sm me-2">
                                            <img  className="avatar-img rounded-circle"  src="assets/img/profiles/avatar-14.jpg" alt="User Image" /></a>
                                        <a href="profile.html">John Smith <span><span className="__cf_email__">john@example.com</span></span></a>
                                    </h2>
                                </td>
                                <td>$1,54,220</td>
                                <td>$1,50,000</td>
                                <td>Cash</td>
                                <td>$2,54,00</td>
                                <td>25 Mar 2023</td>
                                <td><span className="badge bg-success-light">Paid</span></td>
                                <td className="text-end">
                                    <div className="dropdown dropdown-action">
                                        <a href="#" className="btn-action-icon" data-bs-toggle="dropdown"
                                            aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <a className="dropdown-item" href="edit-invoice.html"><i
                                                    className="far fa-edit me-2"></i>Edit</a>
                                            <a className="dropdown-item" href="invoice-details.html"><i
                                                    className="far fa-eye me-2"></i>View</a>
                                            <a className="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal"
                                                data-bs-target="#delete_modal"><i
                                                    className="far fa-trash-alt me-2"></i>Delete</a>
                                            <a className="dropdown-item" href="invoice-details.html"><i
                                                    className="fe fe-send me-2"></i>Send</a>
                                            <a className="dropdown-item" href="invoice-details.html"><i
                                                    className="fe fe-file-text me-2"></i>Clone Invoice</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label className="custom_check">
                                        <input type="checkbox" name="invoice" />
                                        <span className="checkmark"></span>
                                    </label>
                                    <a href="invoice-details.html" className="invoice-link">#4988</a>
                                </td>
                                <td>Advertising</td>
                                <td>16 Mar 2022</td>
                                <td>
                                    <h2 className="table-avatar">
                                        <a href="profile.html" className="avatar avatar-sm me-2">
                                            <img className="avatar-img rounded-circle"  src="assets/img/profiles/avatar-15.jpg" alt="User Image" /></a>
                                        <a href="profile.html">Johnny <span><span className="__cf_email__" >john2@example.com</span></span></a>
                                    </h2>
                                </td>
                                <td>$3,54,220</td>
                                <td>$2,50,000</td>
                                <td>Cheque</td>
                                <td>$4,220</td>
                                <td>16 Jan 2023</td>
                                <td><span className="badge bg-warning-light text-warning">Overdue</span>
                                </td>
                                <td className="text-end">
                                    <div className="dropdown dropdown-action">
                                        <a href="#" className="btn-action-icon" data-bs-toggle="dropdown"
                                            aria-expanded="false"><i className="fas fa-ellipsis-v"></i></a>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <a className="dropdown-item" href="edit-invoice.html"><i
                                                    className="far fa-edit me-2"></i>Edit</a>
                                            <a className="dropdown-item" href="invoice-details.html"><i
                                                    className="far fa-eye me-2"></i>View</a>
                                            <a className="dropdown-item" href="javascript:void(0);" data-bs-toggle="modal"
                                                data-bs-target="#delete_modal"><i
                                                    className="far fa-trash-alt me-2"></i>Delete</a>
                                            <a className="dropdown-item" href="invoice-details.html"><i
                                                    className="fe fe-send me-2"></i>Send</a>
                                            <a className="dropdown-item" href="invoice-details.html"><i
                                                    className="fe fe-file-text me-2"></i>Clone Invoice</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div> */}

 
                  <div className="row">
                    <div className="col-sm-12">
                        <div className="card-table">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-stripped table-hover " id="datatable_2">
                                        <thead className="thead-light">
                                            <tr>   
                                              <th>Action</th>  
                                              <th>ID</th> 
                                              <th>Name | Email</th>
                                              <th>Phone</th>
                                              <th>UTM Source | Reg. Via</th>
                                              <th>Rel Manager</th>
                                              <th>Planner</th>
                                              <th>Created By | Created On</th>
                                              <th>Stage On | Plan Status </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            tableRowsData.map((row, index)=>{

                                             /*  if(tableRowsData.length == index + 1 ){
                                                setTableDrow(true);
                                              }
                                               */

                                              return <tr key={index}> 
                                                 <td className="text-end">
                                                    <div className="dropdown dropdown-action" style={{float: 'left'}}>
                                                        <Link  className="btn-action-icon" data-bs-toggle="dropdown"
                                                            aria-expanded="false"><i className="fas fa-ellipsis-v"></i></Link>
                                                        <div className="dropdown-menu dropdown-menu-end">

                                                          <Link className="dropdown-item"  data-bs-toggle="modal"data-bs-target="#delete_modal" onClick={() => deleteUser(row.id, row.profile_id)} >
                                                            <i className="far fa-trash-alt me-2"></i>Delete user</Link>

                                                            <Link className="dropdown-item" data-bs-toggle="modal"data-bs-target="#delete_modal" onClick={() => RiskLink(row.id, row.enc_profile)}>
                                                            <i className="far fa-paste me-2"></i>Risk Profile Link</Link>
                                                           
                                                            { row.waitlist === 1 &&
                                                           <> <Link className="dropdown-item" data-bs-toggle="modal"data-bs-target="#delete_modal" onClick={() => waitlistPopup(row.profile_id)}>
                                                            <i className="fa fa-user me-2"></i>Mark Active</Link> </>
                                                            }


                                                            <Link className="dropdown-item" data-bs-toggle="modal"data-bs-target="#delete_modal" onClick={() => EditPlanStatus(row.profile_id)}>
                                                            <i className="fa fa-align-center me-2"></i>Update Plan Status</Link>


                                                            <Link className="dropdown-item" data-bs-toggle="modal"data-bs-target="#delete_modal" onClick={() => markTestProfile(row.profile_id)}>
                                                            <i className="fa fa-user-alt-slash me-2"></i>Mark As Test <br /> Profile</Link> 

                                                            <Link className="dropdown-item"  data-bs-toggle="modal"data-bs-target="#delete_modal"  onClick={() => setDefaultPassword(row.email)}>
                                                            <i className="fa fa-unlock me-2"></i>Set Password</Link>

                                                            <Link className="dropdown-item"  data-bs-toggle="modal"data-bs-target="#delete_modal"  onClick={() => get_profile_pan(row.profile_id)}>
                                                            <i className="far fa-id-card me-2"></i>Get PAN</Link>

                                                            <Link className="dropdown-item"  data-bs-toggle="modal"data-bs-target="#delete_modal" onClick={() => { setActive(true); setAction('profileInterest'); setProfileId(row.profile_id) }}>
                                                            <i className="fa fa-tasks me-2"></i>Profile Interest</Link> 

                                                            <Link className="dropdown-item" data-bs-toggle="modal"data-bs-target="#delete_modal" onClick={() => getViewLink(row.ref_link)}>
                                                            <i className="far fa-id-card me-2"></i>View Url</Link> 
                                                        </div>
                                                    </div> 
                                                    <span className='cursor_pointer' onClick={() => loginUser(row.id, row.email)}><i className="fas fa-sync-alt" style={{ width: 35, fontSize: 16, color: '#06B153', cursor:'pinter' }}></i></span>
                                                </td>  
                                                  <td>{row.profile_id}</td> 
                                                  <td>
                                                  <h2 className="table-avatar">
                                                      <Link href="profile.html" className="avatar avatar-sm me-2">
                                                          <img className="avatar-img rounded-circle"  src="assets/img/profiles/avatar-15.jpg" alt="User Image" /></Link>
                                                      <Link href="profile.html">{row.name} <span><span className="__cf_email__" >{row.email}</span></span></Link>
                                                  </h2> 
                                                  </td>
                                                  <td>{row.phone}</td>
                                                  <td>{row.utm_source ? row.utm_source : 'NA'} <br /> {registerVia(row.registerVia)}</td>
                                                  <td>{row.primary_rel_manager}</td>
                                                  <td>{row.plan_ser_manager}</td>
                                                  <td>{row.admin_name}</td>
                                                  <td><span className="badge bg-success-light">{row.planStatusCurrent}</span></td>
                                                 
                                              </tr> 
                                            })
                                          } 
                                        </tbody>
                                    </table>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-dm-12">
                  <strong className='font12 mt-3'>Indicators : Verified Email/Mobile - <span className='text-success'>green</span>, Unverified Email/Mobile- <span className='text-danger'>Red</span></strong>
                </div>

               {/*  <div className="">
                  <div className="admin_dashboard_outer mt30">
                    <div className="dashboard_table">
                      <div className="graphtabs_outer mt25">
                        <div className="graph_container">
                          <div className="dashboardTable">
                            <div className="table-responsive">  

             
                              <DataTable
                                columns={columns}
                                data={tableRowsData}
                                pagination
                                customStyles={customStyles}
                              />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <strong className='font12 mt-3'>Indicators : Verified Email/Mobile - <span className='text-success'>green</span>, Unverified Email/Mobile- <span className='text-danger'>Red</span></strong>
                </div> */}

              </div>
            </div>

            {
              (isActive === true && action === 'addTestProfile') && (<AddTestProfileSidebar setActive={setActive} setAction={setAction} />)
            }

            {
              (isActive === true && action === 'addDirectProfile') && (<AddDirectProfileSidebar setActive={setActive} setAction={setAction} />)
            }


            {
              (isActive === true && action === 'profileInterest') && (<ProfileInterestStatus setActive={setActive} setAction={setAction} profileId={profileId} />)
            }

        </div>
      </div>
   </div>
    </Fragment>
  );
}