import React, { useEffect } from "react";

export default function CardBox(props) {
  return (
    <>
      <div class="col-xl-2 col-lg-4 col-sm-6 col-12">
        <div class="card inovices-card">
          <div class="card-body">
            <div class="dash-widget-header">
              <span class="inovices-widget-icon bg-info-light">
                <img src={props.icon ? props.icon : 'assets/img/icons/receipt-item.svg'} alt="" />
              </span>
              <div class="dash-count">
                <div class="dash-title">{props.title && props.title}</div>
                <div class="dash-counts">
                  <p>{props.value ? props.value : 0}</p>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <p class="inovices-all">
                {props.counter_title && props.counter_title}
                <span class="rounded-circle bg-success-light text-success-light">
                  {props.counter_value && props.counter_value}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
